import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import "../scss/main.scss";

export default function About() {
    return (
        <Layout pageTitle="About Us | Ikshana Learning">
            <section className="hero about-hero-section">
                <h1 className="hero__title">Empowering Students to Succeed</h1>
                <h3 className="hero__subtitle">
          Ikshana Learning's mission is to provide <br /> tailored coaching,
          experienced educators <br />
          to help every student reach their full potential.
                </h3>
            </section>

            <section style={{ position: "relative" }}>
                <div className="why-us__bg-layer">
                    <div className="why-us__bg-layer__portion why-us__bg-layer__portion--left"></div>
                    <div className="why-us__bg-layer__portion why-us__bg-layer__portion--right"></div>
                </div>
                <div
                    className="container why-us__container"
                    style={{ padding: "4rem" }}
                >
                    <h2 className="title color-purple">Our Story</h2>
                    <h3 className="subtitle" style={{ marginBottom: "3rem" }}>
            Journey from chaos to a somewhat organized mess
                    </h3>
                    <h3 className="subtext color-purple">
            Ikshana Learning was founded with the goal of providing high-quality
            education to students from all backgrounds. We believe that everyone
            should have access to education that is tailored to their needs and
            goals, and we strive to make this a reality.
                        <br />
                        <br />
            Ikshana is a Sanskrit word that means "to see, to observe, to study,
            to learn". The word "Learning" refers to the process of acquiring
            new knowledge, skills, or attitudes. So, "Ikshana Learning" can be
            interpreted as the process of observing and studying to acquire new
            knowledge, skills, or attitudes.
                        <br />
                        <br />
            It suggests a holistic approach to learning that goes beyond just
            gaining information but also involves observing, understanding, and
            applying the knowledge to improve oneself.
                    </h3>
                </div>
            </section>
            <section>
                <div className="container programs__container">
                    <h2 className="title">Our Team</h2>
                    <h3 className="subtitle">
            Meet our team of expert trainers at Ikshana Learning
                    </h3>
                    <div className="flex-between" style={{ flexDirection: "column" }}>
                        <div>
                            <StaticImage
                                alt="Mohit Aggarwal"
                                src="../images/about/Mohit.png"
                                placeholder="blurred"
                                layout="fixed"
                                width={200}
                                height={200}
                                className="about-image-border"
                            />
                            <h3 className="title color-purple">Mohit Agarwal</h3>
                            <h4 className="subtitle" style={{ marginBottom: "2rem" }}>
                ACCA, MBA, B.COM
                            </h4>
                        </div>
                        <p className="about-team-text">
              Mohit Agarwal is an experienced academic trainer associated with
              Ikshana Learning and is known for his expertise in international
              courses such as ACCA, CIMA, CMA (US), and CPA.
                            <br />
                            <br />
              He has previously worked with corporations like Globe Commodities
              Limited and IIFL (India Infoline) and specializes in the "IFRS,
              PM, Strategic Business Reporting", "Advanced Performance
              Management", and "Advanced Financial Management" papers of the
              ACCA. <br /> <br />
              He is famous among students for giving good results in
              professional exams like the ACCA and CIMA, and believes in the
              philosophy that "every student can learn, just not on the same day
              or in the same way."
                            <br />
                            <br /> With a deep understanding of the ACCA curriculum and wealth
              of practical experience from his work with various corporations,
              he is able to bring real-world examples to the classroom and is
              known for his ability to break down complex concepts and make them
              easy to understand.{" "}
                        </p>
                    </div>
                    <div
                        className="flex-between"
                        style={{ flexDirection: "column", margin: "8rem 0" }}
                    >
                        <div>
                            <StaticImage
                                alt="Sahil Aggarwal"
                                src="../images/headshots/boy-placeholder.jpeg"
                                placeholder="blurred"
                                layout="fixed"
                                width={200}
                                height={200}
                                className="about-image-border"
                            />
                            <h3 className="title color-purple">Sahil Agarwal</h3>
                            <h4 className="subtitle" style={{ marginBottom: "2rem" }}>
                CA, CFA
                            </h4>
                        </div>
                        <p className="about-team-text">
              Sahil Agarwal is a qualified Chartered accountant and CFA
              Charterholder with over 8 years of professional working experience
              in Top 3, Big 4 and other Tier-1 consulting firms in the domain of
              corporate finance, strategy, assurance, risk advisory, and
              taxation.
                            <br />
                            <br /> He specializes in Equity and credit research, Financial
              modelling and valuation, CFA program, and accountancy. He has a
              strong background in Investment and Portfolio management and uses
              this experience to help students understand the practical
              applications of the CFA curriculum.
                            <br />
                            <br /> He is known for his ability to make difficult concepts easy
              to understand and for his interactive teaching style. He has a
              passion for helping students achieve their professional goals and
              excel in their exams.
                        </p>
                    </div>
                    <div className="flex-between" style={{ flexDirection: "column" }}>
                        <div>
                            <StaticImage
                                alt="Ayushi Goyal"
                                src="../images/about/Ayushi.jpeg"
                                placeholder="blurred"
                                layout="fixed"
                                width={200}
                                height={200}
                                className="about-image-border"
                            />
                            <h3 className="title color-purple">Ayushi Goyal</h3>
                            <h4 className="subtitle" style={{ marginBottom: "2rem" }}>
                CA, B.COM
                            </h4>
                        </div>
                        <p className="about-team-text">
              Ayushi Goyal, a Chartered Accountant with five years of working
              experience at a CA firm, has been teaching international courses
              like ACCA, CIMA, and CMA US for over 3.5 years, specializing in
              "Audit and Assurance", "Taxation", and "Performance Management"
              papers of ACCA.
                            <br />
                            <br /> Known for her enthusiasm for teaching and assisting
              students in their learning experiences, she is also recognized for
              her ability to create a positive and interactive classroom
              environment, and has a strong commitment to helping students
              achieve their professional goals.{" "}
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
